<template>
  <div class="csn-rc-card">
    <img
      class="csn-rc-card-img"
      :src="card.images"
      alt="reward carnival card image"
    />
    <div class="csn-rc-card-info">
      <span class="csn-rc-card-header">
        {{ card.title }}
      </span>
      <span class="csn-rc-card-name">
        {{ card.categories }}
      </span>
      <div class="csn-rc-card-data" v-html="card.description"></div>
      <button class="btn casino-btn csn-rc-card-button">
        <!-- NOTE: the current i18n translator is unable to use variables. The issue will be solve after migration to Vue 3-->
        {{ `${t('buy')} ${card.coinsPrice} ${t('coins')}` }}
      </button>
    </div>
  </div>
</template>
<script>
import { REWARD_CARD } from '@/constants'

export default {
  name: REWARD_CARD,
  props: {
    card: Object,
  },
  computed: {
    t() {
      return this.$createComponentTranslator(REWARD_CARD)
    },
  },
}
</script>
